import React, { lazy, Suspense,useEffect  } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './assets/css/Preloader.css';
import Preloader from './components/preloader';

const AdminBaseComponent = lazy(() => import('./AdminBaseComponent'))
const UserBaseComponent = lazy(() => import('./UserBaseComponent'))

function App() {

  return (
    <>
      <BrowserRouter basename="/">
        <Suspense fallback={Preloader()}>
          <Routes>
            <Route path="/admin/*" element={<AdminBaseComponent />} />
            <Route path="*" element={<UserBaseComponent />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
